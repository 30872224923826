<template>
  <v-row>
    <v-col cols="12">
       <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn class="ma-2" dark color="danger" @click="nuevoViaje">
                            <v-icon>mdi-plus</v-icon>
                           Nuevo Viaje
                        </v-btn>
                     <!--   <div>
                            <v-btn class="ma-2" color="primary">
                                <v-icon>mdi-cog</v-icon>
                            </v-btn>
                            <v-btn outlined class="ma-2">Import</v-btn>
                            <v-btn outlined class="ma-2">Export</v-btn>
                        </div>-->
                    </div>
                </v-card-title>

        <br />
 <v-card-title>
                    Viajes
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Búsqueda"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                 <v-data-table
                 locale="es"
                    :search="search"
                    :headers="headers"
                    :items="lista_viajes"
                    item-key="folio"
                    
                    class="elevation-1 table-one"
                    multi-sort
                >
                 <template v-slot:item.action="{item}">

                        <div class="d-flex">
                                 <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                    @click="goViaje(item.idviaje)"
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                    >
                                        <v-icon>mdi-file-download-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Descargar cartaporte</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                    @click="goViaje(item.idviaje)"
                                        color="success"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                    >
                                        <v-icon>mdi-tab-search</v-icon>
                                    </v-btn>
                                </template>
                                <span>Ver detalles de Viaje</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="danger"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        @click="eliminarCliente(item)"
                                    >
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Eliminar</span>
                            </v-tooltip>
                        </div>
                    </template>
                 </v-data-table>
       
             
    
      </panel>
             </base-card>

    </v-col>
  </v-row>
</template>
<script>
import { url } from "../../main.js";

export default {
  components: {
  },
  data() {
    return {
      search: '',
        headers: [
                {
                    text: 'Folio',
                    align: 'start',
                    sortable: false,
                    value: 'folio'
                },
               
                {text: 'Fecha de Carga', value: 'fecha_carga'},
                {text: 'Fecha de Descarga', value: 'fecha_descarga'},
                {text: 'Cliente', value: 'razon_social'},
               {text: 'Estatus', value: 'estado'},

                {text: 'Acción', value: 'action'}
            ],
      lista_viajes: [],
      busqueda_folio: "",
      busqueda_cliente: "",
      currentSort: "",
      currentSortDir: "",
      pageSize: 10,
      currentPage: 1,
    };
  },
  mounted()
  {
    this.getViajes();

  },
      computed: {
    
    },
  
  methods: {
      goViaje(idviaje) {
      this.$router.push("/operacion/viaje/"+idviaje);
    },
    nuevoViaje() {
      this.$router.push("/operacion/viaje/0");
    },
    getViajes() {
      this.axios
        .get(url + "apiViaje.php/viajes")
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_viajes = respuesta.data;

          console.log(this.lista_viajes);
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
      downloadCartaporte(idcartaporte){
        if(idcartaporte!=null)
        {
      window.open("http://gruposaeta.com.mx/wsGSaeta/apiCartaporte.php/cartaporte_excel/"+idcartaporte,"_blank");
        }else{
          
          this.$swal("Viajes","Aún no se ha generado un cartaporte para este viaje","warning");

        }
    },
      prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage * this.pageSize < this.lista_viajes.length)
        this.currentPage++;
    },
    sort(s) {
      console.log("sort");
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    }
  
  },
};
</script>

